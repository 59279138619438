.ReactVirtualized__Grid.ReactVirtualized__Table__Grid::-webkit-scrollbar-thumb {
  background: #c7c7c7 !important;
  border-radius: 10px;
}

.ReactVirtualized__Grid.ReactVirtualized__Table__Grid::-webkit-scrollbar {
  width: 6px !important;
}

.ReactVirtualized__Table__headerRow {
  background: #fff;
  border-bottom: 1px solid #ececec;
}

.ReactVirtualized__Table__row:hover {
  background-color: #ececec;
}
